import Script from "next/script";
import React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { useConsentGiven } from "@/utils/hooks/useConsentGiven";

export type InstaPost = {
  name?: string;
  embedCode: string;
  fullWidth?: boolean;
};

const StyledPost = styled.article<{ isTiktok: boolean; fullWidth: boolean }>`
  border-radius: 8px;
  overflow: hidden;
  // Set the width to 100% if it's not a tiktok post (tiktok posts have a fixed width of 323px)
  width: ${({ isTiktok }) => (isTiktok ? "unset" : "100%")};
  border: 2px solid ${({ theme }) => theme.colors.tertiary.normal};

  @media ${({ theme }) => theme.devices.tablet} {
    max-width: ${({ fullWidth }) => (fullWidth ? "500px" : "100%")};
  }

  iframe,
  blockquote {
    max-width: 100% !important;
    width: 100% !important;
    border: none !important;
    margin: unset !important;
  }

  blockquote {
    background-color: transparent !important;
    position: relative;
    width: 323px !important;
    min-width: 323px !important;

    > div {
      height: 100%;
      position: relative;

      a {
        height: 100%;

        > div:not([style*="height:50px; margin:0 auto 12px; width:50px;"]) {
          display: none !important;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  p {
    display: none;
  }
`;

export default function InstagramPost({
  name,
  embedCode,
  fullWidth = false,
}: InstaPost) {
  const id = `ig-embed-${uuidv4()}`;

  // this regex will parse through the html block looking for a script tag, if it finds one, the value will be the src from the script
  const scriptSRC = embedCode.match(/src="([^"]*)"/)?.[1];
  const { isConsentGiven } = useConsentGiven();
  const isTiktok = embedCode.includes("tiktok");

  return (
    <>
      {!isConsentGiven ? (
        <p className="mx-2 text-center text-white">
          Cookies moeten worden geaccepteerd om deze post te kunnen zien
        </p>
      ) : (
        <StyledPost isTiktok={isTiktok} fullWidth={fullWidth}>
          <div
            dangerouslySetInnerHTML={{
              __html: embedCode,
            }}
          />
          {scriptSRC && <Script src={scriptSRC} id={id} />}
        </StyledPost>
      )}
    </>
  );
}
