import React, { useEffect, useState } from "react";

import Tag from "@/components/buttons/Tag/Tag";
import { useConsentGiven } from "@/utils/hooks/useConsentGiven";

import { SpotifyPropsType } from "./SpotifyPost.types";

export default function SpotifyPost({
  songLink,
  categories,
}: SpotifyPropsType) {
  const [finalTrackUrl, setFinalTrackUrl] = useState("");
  const { isConsentGiven } = useConsentGiven();

  useEffect(() => {
    if (isConsentGiven) {
      createTrackUrl();
    }
  }, [songLink, isConsentGiven]);

  const createTrackUrl = () => {
    const trackId = songLink
      ?.replace(/https:\/\/open\.spotify\.com\/(embed\/)?(track|episode)\//, "")
      .split("?")[0];
    setFinalTrackUrl(
      `https://open.spotify.com/embed/${
        songLink?.indexOf("/track/") !== -1 ? "track" : "episode"
      }/${trackId}?utm_source=generator&theme=0`,
    );
  };

  return (
    <div className={`my-4 flex flex-col gap-4`}>
      <iframe
        style={{ borderRadius: "12px" }}
        src={finalTrackUrl}
        width="100%"
        height="352"
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        allowTransparency={true}
        loading="lazy"
        className="z-10"
      ></iframe>

      <div className={"flex flex-wrap justify-center gap-2 text-center"}>
        {!isConsentGiven && (
          <p className="mb-5 text-white">
            Cookies moeten worden geaccepteerd om deze post te luisteren
          </p>
        )}
        {categories &&
          categories.map((c) => {
            return (
              <Tag
                key={c.id}
                variant="light"
                size="m"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#3FC7B4",
                }}
              >
                {c.name}
              </Tag>
            );
          })}
      </div>
    </div>
  );
}
